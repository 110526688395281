import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {config, withCsrf} from "../util/consts";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {ForgotPasswordDTO, SignInDTO} from "../model/Model";
import {Card} from "primereact/card";
import {Divider} from "primereact/divider";
import {Toast} from "primereact/toast";
import {useSearchParams} from "react-router-dom";

export default function SignInPage() {

	const [searchParams] = useSearchParams();
	const toast = useRef<Toast>(null);
	const [showSignInCard, setShowSignInCard] = useState<boolean>(true)
	const [signIn, setSignIn] = useState<SignInDTO>({
		username: config.signInDefaults.username,
		password: config.signInDefaults.password,
		redirectUrl: '',
		_csrf: ''
	});
	const [forgotPassword, setForgotPassword] = useState<ForgotPasswordDTO>({
		email: "a.dministrator@ACME.com",
	});

	const signInButton = () => {
		withCsrf()
			.then(csrf => {
				signIn._csrf = csrf.token
				signIn.redirectUrl = searchParams.get("redirect_url")
				axios.postForm<string>(config.url.API_URL + "../sign-in", signIn)
					.then(response => document.location = response.data)
					.catch(() =>
						toast.current!.show({
							severity: "error",
							summary: "Unable to sign-ing",
							detail: "Your provided an incorrect credential."
						})
					)
			})
	}

	const forgotPasswordButton = () => {
		withCsrf()
			.then(csrf => {
				axios.post<void>(config.url.API_URL + "reset-password/email", forgotPassword, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				})
					.then(() => {
						toast.current!.show({
							severity: "info",
							summary: "Forgot password",
							detail: "We've send a mail to " + forgotPassword.email + " with the instructions to reset your password."
						});
					});
			});
	}

	const toForgotPasswordButton = () => {
		setForgotPassword({
			...forgotPassword,
			email: signIn.username
		})
		setShowSignInCard(false)
	}
	const backToSignInButton = () => {
		setSignIn({
			...signIn,
			username: forgotPassword.email
		})
		setShowSignInCard(true)
	}

	useEffect(() => {
		document.body.style.backgroundColor = "black";
	})

	return (
		<>
			<Toast ref={toast}/>
			<Card hidden={!showSignInCard}>
				<div className="flex flex-column gap-2" style={{width: "500px"}}>
					<div style={{marginBottom: "20px"}}>
						Enter your email and your password to get into your Liquisens dashboard.
					</div>
					<InputText id="username" value={signIn?.username} type="email"
							   placeholder="Enter your e-mail address"
							   onChange={(e) => setSignIn({
								   ...signIn,
								   username: e.target.value
							   })}/>
					<InputText id="password" value={signIn?.password} type="password"
							   placeholder="Enter your password"
							   onChange={(e) => setSignIn({
								   ...signIn,
								   password: e.target.value
							   })}/>

					<Button label="Sign in" onClick={signInButton} style={{marginTop: "30px"}}/>
					<Divider className="justify-content-center">
						<b>or</b>
					</Divider>
					<Button label="Forgot password" link onClick={toForgotPasswordButton}/>
				</div>
			</Card>

			<Card hidden={showSignInCard}>
				<div className="flex flex-column gap-2" style={{width: "500px"}}>
					<div style={{marginBottom: "20px"}}>
						Enter your email and we'll send you a link to reset your password and get back into your
						account.
					</div>
					<InputText id="email" value={forgotPassword?.email} type="email"
							   placeholder="Enter your e-mail address"
							   onChange={(e) => setForgotPassword({
								   ...forgotPassword,
								   email: e.target.value
							   })}/>

					<Button label="Send reset password mail" onClick={forgotPasswordButton}
							style={{marginTop: "30px"}}/>
					<Divider className="justify-content-center">
						<b>or</b>
					</Divider>
					<Button label="Back to sign in" link onClick={backToSignInButton}/>
				</div>
			</Card>

		</>
	);
}
