import {DataTable, DataTableSelectionSingleChangeEvent, DataTableValueArray} from "primereact/datatable";
import React from "react";

export default function LiquisensDataTable<TValue extends DataTableValueArray>(props: {
	children?: React.ReactNode
	value?: TValue | undefined;
	selection?: TValue[number] | undefined | null,
	onSelectionChange?(event: DataTableSelectionSingleChangeEvent<TValue>): void
}) {

	return (
		<DataTable stripedRows size="small" value={props.value} tableStyle={{minWidth: '50rem'}}
				   paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]}
				   selectionMode="single" selection={props.selection}
				   onSelectionChange={props.onSelectionChange}
		>
			{props.children}
		</DataTable>

	)
}
