import React from 'react';
import ChartPage from "./pages/ChartPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './template.css';
import UserPage from "./pages/UserPage";
import SignInPage from "./pages/SignInPage";
import NonPublicTemplatePage from "./pages/NonPublicTemplatePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import PublicTemplatePage from "./pages/PublicTemplatePage";
import DevicePage from "./pages/DevicePage";
import AccessPage from "./pages/AccessPage";
import CalibrationPage from "./pages/CalibrationPage";

function App() {

	const router = createBrowserRouter([
			{
				path: '/',
				element: <NonPublicTemplatePage/>,
				children: [
					{
						path: 'graph/:deviceId?',
						element: <ChartPage/>,
					},
					{
						path: 'user',
						element: <UserPage/>,
					},
					{
						path: 'calibration',
						element: <CalibrationPage/>,
					},
					{
						path: 'device',
						element: <DevicePage/>,
					},
					{
						path: '', // root page
						element: <DevicePage/>,
					},
					{
						path: 'access',
						element: <AccessPage/>,
					},
				]
			},
			{
				path: '/',
				element: <PublicTemplatePage/>,
				children: [
					{
						path: 'sign-in',
						element: <SignInPage/>,
					},
					{
						path: 'reset-password/:resetPasswordToken',
						element: <ResetPasswordPage/>,
					},
				]
			},
		])
	;

	return (
		<RouterProvider router={router}/>
	);
}

export default App;
