import React, {useEffect, useRef, useState} from 'react';
import {Column} from "primereact/column";
import axios from "axios";
import {config, withCsrf} from "../util/consts";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import UserDTO, {DropdownDTO, ValidationStatus} from "../model/Model";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {classNames} from "primereact/utils";
import {Toast} from "primereact/toast";
import LiquisensDataTable from "../components/LiquisensDataTable";
import {useFormik} from "formik";
import {Message} from "primereact/message";
import {Dropdown} from "primereact/dropdown";

export default function UserPage() {

	const toast = useRef<Toast>(null);
	const [users, setUsers] = useState<UserDTO[]>();
	const [languages, setLanguages] = useState<DropdownDTO[]>();
	const [visible, setVisible] = useState<boolean>(false);
	const [initialValues, setInitialValues] = useState<UserDTO>({
		id: null,
		firstName: "",
		lastName: "",
		email: "",
		mobile: "",
		administrator: false,
		language: "",
	});

	const loadData = () => {
		axios.get<UserDTO[]>(config.url.API_URL + "user")
			.then(response => setUsers(response.data))
		axios.get<DropdownDTO[]>(config.url.API_URL + "language/dropdown")
			.then(response => setLanguages(response.data))
	}

	const loadUser = (id: string | null) => {
		axios.get<UserDTO>(config.url.API_URL + "user/" + id)
			.then(response => {
				setInitialValues(response.data);
			})
	}

	useEffect(() => {
			loadData();
		}, []
	)

	const addUser = () => {
		setVisible(true);
		setInitialValues({
			id: null,
			firstName: "",
			lastName: "",
			email: "",
			mobile: "",
			administrator: false,
			language: ""
		});
	}

	const editUser = (user: UserDTO) => {
		loadUser(user.id);
		setVisible(true);
	}

	const deleteUser = (user: UserDTO) => {
		withCsrf()
			.then(csrf => {
					axios.delete<void>(config.url.API_URL + "user/" + user.id, {
						headers: {
							'X-CSRF-TOKEN': csrf.token
						}
					})
						.then(response => {
								loadData()
								toast.current!.show({
									severity: "info",
									summary: "User deleted",
									detail: "The user has been deleted from the platform."
								});
							}
						)
				}
			)
	}

	const resetPassword = (user: UserDTO) => {
		axios.get(config.url.API_URL + "user/" + user.id + "/send-reset-password-link")
			.then(response => {
				toast.current!.show({
					severity: "info",
					summary: "Send reset password link",
					detail: "We've send a mail with a link to reset the password."
				});
				setVisible(false)
			});
	}

	const saveUser = (user: UserDTO) => {
		withCsrf()
			.then(csrf => {
				axios.post<UserDTO>(config.url.API_URL + "user", user, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				})
					.then(response => {
						loadData()
						setVisible(false)
						toast.current!.show({
							severity: "info",
							summary: "User saved",
							detail: "The user has been saved to the database."
						})
					});
			});
	}

	const formik = useFormik<UserDTO>({
		initialValues: initialValues,
		enableReinitialize: true,
		validate: async userDTO => {
			return withCsrf()
				.then(async csrf => {
					return await axios.post<ValidationStatus>(config.url.API_URL + "user/validate", userDTO, {
						headers: {
							'X-CSRF-TOKEN': csrf.token
						}
					})
						.then(response => {
							return response.data.issues;
						});
				});
		},
		onSubmit: userDTO => saveUser(userDTO),
	})

	const actionButtons = (user: UserDTO) => {
		return (
			<>
				<div className="flex flex-row gap-2">
					<Button icon="pi pi-trash" severity="danger" size="small" tooltip="Delete"
							onClick={() => deleteUser(user)}/>
					<Button icon="pi pi-key" severity="secondary" size="small" tooltip="Reset password"
							onClick={() => resetPassword(user)}/>
					<Button icon="pi pi-pencil" severity="secondary" size="small" tooltip="Edit"
							onClick={() => editUser(user)}/>
				</div>
			</>
		);
	};

	const adminFlagTemplate = (user: UserDTO) => {
		return <i className={classNames('pi', {
			'true-icon pi-check-circle': user.administrator,
			'false-icon pi-times-circle': !user.administrator
		})}></i>;
	};

	return (
		<>
			<Toast ref={toast}/>
			<div className="flex">
				<div className="flex-1">
					<h1>Users</h1>
				</div>
				<div className="flex-1">
					<div className="flex justify-content-end align-self-center">
						<Button icon="pi pi-plus" label="Add user" onClick={() => addUser()} size="small"/>
					</div>
				</div>
			</div>
			<div>
				<LiquisensDataTable value={users} selection={formik.values}>
					<Column field="firstName" header="First name" sortable></Column>
					<Column field="lastName" header="Last name" sortable></Column>
					<Column field="email" header="Email" sortable></Column>
					<Column field="mobile" header="Mobile" sortable></Column>
					<Column field="administrator" header="Administrator" body={adminFlagTemplate} sortable></Column>
					<Column field="displayLanguage" header="Language" sortable></Column>
					<Column body={actionButtons} exportable={false} style={{width: '50px'}}></Column>
				</LiquisensDataTable>
			</div>
			<Dialog header="User" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
				<div className="modal">
					<form onSubmit={formik.handleSubmit}>
						<div className="card flex">
							<div className="flex flex-column gap-2 flex-1">

								<label htmlFor="firstName">First name</label>
								<InputText id="firstName" value={formik.values.firstName}
										   onChange={formik.handleChange}/>
								<label htmlFor="lastName">Last name</label>
								<InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange}/>
								<label htmlFor="email">E-mail</label>
								<InputText id="email" value={formik.values.email} onChange={formik.handleChange}/>
								{formik.errors.email && <Message severity="error" text={formik.errors.email}/>}
								<label htmlFor="mobile">Mobile</label>
								<InputText id="mobile" value={formik.values.mobile} onChange={formik.handleChange}/>
								{formik.errors.mobile && <Message severity="error" text={formik.errors.mobile}/>}
								<label htmlFor="administrator">Administrator</label>
								<Checkbox id="administrator" checked={formik.values.administrator}
										  onChange={formik.handleChange}/>
								<label htmlFor="language">Language</label>
								<Dropdown options={languages} value={formik.values.language}
										  id="language"
										  placeholder="Select a Language"
										  onChange={formik.handleChange}
								/>

								<div className="flex flex-row gap-2">
									<div className="flex-1">
										<Button label="Cancel" severity="secondary" onClick={() => setVisible(false)}/>
									</div>
									<div className="flex-1">
										<div className="flex justify-content-end align-self-center gap-2">
											<Button label="Reset password" icon="pi pi-key" severity="secondary"
													onClick={e => resetPassword(formik.values)}
													visible={formik.values.id !== ""}/>
											<Button label="Submit" icon="pi pi-save" type="submit"
													disabled={!formik.isValid}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Dialog>
		</>
	);
}
