import React, {useEffect, useState} from "react";
import {config, withCsrf} from "../util/consts";
import {Menu} from "primereact/menu";
import axios from "axios";
import {MenuItem} from "primereact/menuitem";

export default function MenuComponent() {

	const [items, setItems] = useState<MenuItem[]>();

	const loadMenu = () => {
		axios.get<MenuItem[]>(config.url.API_URL + "menu")
			.then(response => {
				setItems(response.data.concat({
					label: 'Sign out',
					icon: 'pi pi-fw pi-lock',
					command: () => {
						signOut()
					}
				}))
			});
	}

	useEffect(() => {
		loadMenu()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const signOut = () => {
		withCsrf()
			.then(csrf => {
				axios.postForm<void>(config.url.API_URL + "../sign-out", null, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				})
					.then(response => document.location = "/sign-in")
			})
	}

	return (
		<Menu model={items} style={{width: 250}}/>
	);

}
