import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {config, withCsrf} from "../util/consts";
import {Button} from "primereact/button";
import {ResetPasswordDTO} from "../model/Model";
import {Card} from "primereact/card";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";

export default function ResetPasswordPage() {

	const toast = useRef<Toast>(null);
	const {resetPasswordToken} = useParams()
	const [resetPasswordTokenValid, setResetPasswordTokenValid] = useState<boolean>(false)

	const [resetPassword, setResetPassword] = useState<ResetPasswordDTO>({
		password: "",
		verifyPassword: ""
	});

	const resetPasswordButton = () => {
		withCsrf()
			.then(csrf => {
				axios.post<boolean>(config.url.API_URL + "reset-password/" + resetPasswordToken, resetPassword, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				})
					.then(response => {
						if (response.data) {
							toast.current!.show({
								severity: "info",
								summary: "(Re)set password",
								detail: "You're password has been (re)set. You will be redirected to the sign in page in 2 seconds."
							});
							setTimeout(() => document.location = "/sign-in", 2000)
						} else {
							toast.current!.show({
								severity: "error",
								summary: "(Re)set password",
								detail: "We couldn't set your new password.  Try again."
							});
						}
					});
			});
	}

	const validateResetPasswordToken = () => {
		axios.get<boolean>(config.url.API_URL + "reset-password/" + resetPasswordToken + "/valid")
			.then(response => {
				setResetPasswordTokenValid(response.data)
			})
	}

	useEffect(() => {
		document.body.style.backgroundColor = "black";
		validateResetPasswordToken()
	})

	return (
		<>
			<Toast ref={toast}/>
			<Card hidden={!resetPasswordTokenValid}>
				<div className="flex flex-column gap-2" style={{width: "500px"}}>
					<label htmlFor="password">New password</label>
					<InputText id="password" value={resetPassword?.password} type="password"
							   placeholder="Enter your new password"
							   onChange={(e) => setResetPassword({
								   ...resetPassword,
								   password: e.target.value
							   })}/>

					<label htmlFor="verifyPassword">Verify the new password</label>
					<InputText id="verifyPassword" value={resetPassword?.verifyPassword} type="password"
							   placeholder="Verify your new password"
							   onChange={(e) => setResetPassword({
								   ...resetPassword,
								   verifyPassword: e.target.value
							   })}/>

					<Button label="(Re)set password" onClick={resetPasswordButton}
							style={{marginTop: "30px"}}/>
				</div>
			</Card>
			<Card hidden={resetPasswordTokenValid}>
				<div className="flex flex-column gap-2" style={{width: "500px"}}>
					The provided reset password token is no longer valid.
				</div>
			</Card>
		</>
	);
}
