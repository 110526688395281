import React from "react";
import {Outlet} from "react-router-dom";
import {Image} from "primereact/image";
import liquisensLogo from '../images/logo.png';

export default function PublicTemplatePage() {

	return (
		<>
			<div style={{height: "200px"}} className="flex flex-1 justify-content-center align-items-center">
				<Image src={liquisensLogo}/>
			</div>
			<div className="flex flex-1">
				<div className="flex flex-1 justify-content-center">
					<Outlet/>
				</div>
			</div>
		</>
	);
}
