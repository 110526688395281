import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import axios from "axios";
import {Dropdown} from "primereact/dropdown";
import {config, formatDate} from "../util/consts";
import {useParams, useSearchParams} from "react-router-dom";
import {DropdownDTO} from "../model/Model";
import {Calendar} from "primereact/calendar";
import moment from "moment-timezone";

export default function ChartPage() {

	const {deviceId} = useParams()
	const [searchParams] = useSearchParams();

	const [devices, setDevices] = useState<DropdownDTO[]>();
	const [selectedDevice, setSelectedDevice] = useState<string>();
	const [selectedTimeRange, setSelectedTimeRange] = useState<(Date | null)[]>(
		[
			searchParams.has("from") ? new Date(searchParams.get("from")!) : new Date(new Date().setDate(new Date().getDate() - 30)),
			searchParams.has("until") ? new Date(searchParams.get("until")!) : new Date()
		]
	);

	const [dataLoaded, setDataLoaded] = useState<boolean>(false);

	function chartDevice(device: string, range: (Date | null)[]) {
		if (range.length === 2 && range[0] != null && range[1] != null) {
			document.location = config.url.UI_URL + 'graph/' + device + '?from=' + formatDate(range[0]) + '&until=' + formatDate(range[1]);
		}
	}

	function genericOptions(id: string): any {
		return {
			chart: {
				id: id,
				type: 'line',
				group: 'telemetry-group',
			},
			noData: {
				text: "Loading..."
			},
			colors: ["#5B9EF5"],
			legend: {
				show: false,
			},
			xaxis: {
				type: "datetime",
			},
			yaxis: {
				labels: {
					minWidth: 40,
				},
			},
			annotations: {
				xaxis: []
			},
			stroke: {
				width: [5, 10],
			},
			tooltip: {
				x: {
					formatter: function (timestamp:number) {
						return moment(timestamp).format('DD/MM/YYYY HH:mm [(GMT]ZZ[)]');
					}
				}
			},
			series: []
		};
	}

	function timelineOptions(): any {
		return {
			chart: {
				id: 'timeline',
				type: 'area',
				brush: {
					target: 'temperature',
					enabled: true
				},
				selection: {
					enabled: true,
				},
				events: {
					selection: function (chartContext: any, {xaxis, yaxis}: any) {
						let timespan = {
							xaxis: {
								min: xaxis.min,
								max: xaxis.max
							}
						};
						ApexCharts.getChartByID("ph")?.updateOptions(timespan);
					}
				}
			},
			noData: {
				text: "Loading..."
			},
			colors: ['#008FFB'],
			fill: {
				type: 'gradient',
				gradient: {
					opacityFrom: 0.91,
					opacityTo: 0.1,
				}
			},
			xaxis: {
				type: 'datetime',
				tooltip: {
					enabled: false
				}
			},
			yaxis: {
				tickAmount: 2,
			},
			series: []
		}
	}

	useEffect(() => {
			const loadDevices = () => {
				axios.get<DropdownDTO[]>(config.url.API_URL + "device/dropdown")
					.then(response => {
						setDevices(response.data);
						if (deviceId !== undefined) {
							let filter = response.data.filter(device => {
								return device.value === deviceId
							})[0];
							setSelectedDevice(filter.value)
						}
					})
			}

			const loadDataUsage = (id: string, range: (Date | null)[]) => {
				if (range[1] === null)
					return

				axios.get<any>(config.url.API_URL + "chart/device/" + id + "/telemetry/" + formatDate(range[0]) + "/" + formatDate(range[1]))
					.then(response => {

						let responseContainsValues = response.data.ph.data.length !== 0;
						if (!responseContainsValues) {
							return
						}
						setDataLoaded(responseContainsValues)

						ApexCharts.getChartByID("ph")?.updateSeries([response.data.ph]);
						ApexCharts.getChartByID("ec")?.updateSeries([response.data.ec]);
						ApexCharts.getChartByID("temperature")?.updateSeries([response.data.temperature]);
						ApexCharts.getChartByID("orp")?.updateSeries([response.data.orp]);

						ApexCharts.getChartByID("timeline")?.updateSeries([response.data.temperature]); // only take the temperature series (skip the outliers)

						axios.get<any>(config.url.API_URL + "chart/device/" + id + "/outliers/" + formatDate(range[0]) + "/" + formatDate(range[1]))
							.then(response => {
								for (let annotation of response.data) {
									if (annotation.graph === 'PH')
										ApexCharts.getChartByID("ph")?.addXaxisAnnotation(annotation);
									else if (annotation.graph === 'EC')
										ApexCharts.getChartByID("ec")?.addXaxisAnnotation(annotation);
									else if (annotation.graph === 'TEMPERATURE')
										ApexCharts.getChartByID("temperature")?.addXaxisAnnotation(annotation);
									else if (annotation.graph === 'ORP')
										ApexCharts.getChartByID("orp")?.addXaxisAnnotation(annotation);
								}
							})

					})
			};

			loadDevices();

			if (deviceId !== undefined) {
				loadDataUsage(deviceId, selectedTimeRange)
			}

		}, [deviceId, selectedTimeRange, dataLoaded]
	)

	function chart() {
		return (
			<div className="app">
				<div className="row">
					<div className="mixed-chart">
						<h3>Timeline</h3>
						<Chart options={timelineOptions()} type="area" series={[]} height="100" width="90%"/>
						<h3>pH</h3>
						<Chart options={genericOptions('ph')} series={[]} height="250" width="90%"/>
						<h3>EC</h3>
						<Chart options={genericOptions('ec')} series={[]} height="250" width="90%"/>
						<h3>Temperature</h3>
						<Chart options={genericOptions('temperature')} series={[]}
							   height="250"
							   width="90%"/>
						<h3>ORP</h3>
						<Chart options={genericOptions('orp')} series={[]} height="250" width="90%"/>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="flex">
				<div className="flex-1">
					<h1>Charts</h1>
				</div>
			</div>
			<div className="flex flex-row gap-4 align-items-center">
				<label htmlFor="selectDevice">Select a device</label>
				<Dropdown placeholder="Select a device"
						  id="selectDevice"
						  options={devices} value={selectedDevice}
						  editable
						  onChange={(e) => chartDevice(e.value, selectedTimeRange)}
				/>
				<label htmlFor="selectTimeRange">Select a time range</label>
				<Calendar value={selectedTimeRange}
						  dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection
						  onChange={(e) => setSelectedTimeRange(e.value!)}
						  onHide={() => chartDevice(selectedDevice!, selectedTimeRange)}
				/>
			</div>
			{deviceId !== undefined && dataLoaded
				? chart()
				: <div>
					No data found for this selection. Please change your selection criteria.
				</div>
			}
		</>
	);
}
