import MenuComponent from "../components/MenuComponent";
import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";

export default function NonPublicTemplatePage() {

	useEffect(() => {
		document.body.style.backgroundColor = "white";
	})

	return (
		<div className="container">
			<div className="menu" style={{marginTop: "80px"}}>
				<MenuComponent/>
			</div>
			<div className="content">
				<Outlet />
			</div>
		</div>
	);
}
