import React, {useEffect, useRef, useState} from 'react';
import {Column} from "primereact/column";
import axios from "axios";
import {config, showReasonOnToast, withCsrf} from "../util/consts";
import {AccessDTO, DropdownDTO} from "../model/Model";
import {Toast} from "primereact/toast";
import LiquisensDataTable from "../components/LiquisensDataTable";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";

export default function AccessPage() {

	const toast = useRef<Toast>(null);
	const [accesses, setAccesses] = useState<AccessDTO[]>();
	const [selectedAccess, setSelectedAccess] = useState<AccessDTO>({
		id: null,
		user: "",
		userName: "",
		device: "",
		deviceName: "",
		mobileAlert: false,
		mailAlert: false,
	});
	const [users, setUsers] = useState<DropdownDTO[]>();
	const [devices, setDevices] = useState<DropdownDTO[]>();
	const [visible, setVisible] = useState<boolean>(false);

	const loadAccesses = () => {
		axios.get<AccessDTO[]>(config.url.API_URL + "access")
			.then(response => setAccesses(response.data))
	}

	const loadDropdowns = () => {
		axios.get<DropdownDTO[]>(config.url.API_URL + "user/dropdown")
			.then(response => setUsers(response.data))
		axios.get<DropdownDTO[]>(config.url.API_URL + "device/dropdown")
			.then(response => setDevices(response.data))
	};

	const loadAccess = (id: string | null) => {
		axios.get<AccessDTO>(config.url.API_URL + "access/" + id)
			.then(response => {
				setSelectedAccess(response.data)
			})
	}
	useEffect(() => {
			loadAccesses();
			loadDropdowns();
		}, []
	)

	const addAccess = () => {
		setVisible(true);
		setSelectedAccess({
			id: null,
			user: "",
			userName: "",
			device: "",
			deviceName: "",
			mobileAlert: false,
			mailAlert: false
		})
	}

	const editAccess = (access: AccessDTO) => {
		loadAccess(access.id);
		setVisible(true);
	}

	const deleteAccess = (access: AccessDTO) => {
		withCsrf()
			.then(csrf => {
					axios.delete<void>(config.url.API_URL + "access/" + access.id, {
						headers: {
							'X-CSRF-TOKEN': csrf.token
						}
					})
						.then(response => {
								loadAccesses()
								toast.current!.show({
									severity: "info",
									summary: "Access deleted",
									detail: "The access has been deleted from the platform."
								});
							}
						)
				}
			)
	}

	const saveAccess = () => {
		withCsrf()
			.then(csrf => {
				axios.post<AccessDTO>(config.url.API_URL + "access", selectedAccess, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				})
					.then(response => {
						loadAccesses()
						setVisible(false)
						toast.current!.show({
							severity: "info",
							summary: "Access saved",
							detail: "The access has been saved to the database."
						})
					})
					.catch(reason => showReasonOnToast(reason, toast))
			})
	}

	const actionButtons = (access: AccessDTO) => {
		return (
			<>
				<div className="flex flex-row gap-2">
					<Button icon="pi pi-trash" severity="danger" size="small" tooltip="Delete"
							onClick={() => deleteAccess(access)}/>
					<Button icon="pi pi-pencil" severity="secondary" size="small" tooltip="Edit"
							onClick={() => editAccess(access)}/>
				</div>
			</>
		);
	};

	const mobileFlagTemplate = (access: AccessDTO) => {
		return <i className={classNames('pi', {
			'true-icon pi-check-circle': access.mobileAlert,
			'false-icon pi-times-circle': !access.mobileAlert
		})}></i>;
	};

	const mailFlagTemplate = (access: AccessDTO) => {
		return <i className={classNames('pi', {
			'true-icon pi-check-circle': access.mailAlert,
			'false-icon pi-times-circle': !access.mailAlert
		})}></i>;
	};

	return (
		<>
			<Toast ref={toast}/>
			<div className="flex">
				<div className="flex-1">
					<h1>Access</h1>
				</div>
				<div className="flex-1">
					<div className="flex justify-content-end align-self-center">
						<Button icon="pi pi-plus" label="Add access" onClick={() => addAccess()} size="small"/>
					</div>
				</div>
			</div>
			<div>
				<LiquisensDataTable
					value={accesses}
					selection={selectedAccess}
				>
					<Column field="userName" header="User" sortable></Column>
					<Column field="deviceName" header="Device" sortable></Column>
 					<Column field="mobileAlert" header="Mobile alert" body={mobileFlagTemplate} sortable></Column>
					<Column field="mailAlert" header="Mail alert" body={mailFlagTemplate} sortable></Column>
					<Column body={actionButtons} exportable={false} style={{width: '50px'}}></Column>
				</LiquisensDataTable>
			</div>
			<Dialog header="Access" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
				<div className="modal">
					<div className="card flex">
						<div className="flex flex-column gap-2 flex-1">

							<label htmlFor="user">User</label>
							<Dropdown options={users} value={selectedAccess.user}
									  placeholder="Select a User"
									  onChange={e =>
										  setSelectedAccess({
											  ...selectedAccess,
											  user: e.value
										  })}
							/>
							<label htmlFor="device">Device</label>
							<Dropdown options={devices} value={selectedAccess.device}
									  placeholder="Select a Device"
									  onChange={e =>
										  setSelectedAccess({
											  ...selectedAccess,
											  device: e.value
										  })}
							/>
							<label htmlFor="mobileAlert">Mobile alert</label>
							<Checkbox id="mobileAlert" checked={selectedAccess.mobileAlert} onChange={e =>
								setSelectedAccess({
									...selectedAccess,
									mobileAlert: e.target.checked === undefined ? false : e.target.checked
								})}/>
							<label htmlFor="mailAlert">Mail alert</label>
							<Checkbox id="mailAlert" checked={selectedAccess.mailAlert} onChange={e =>
								setSelectedAccess({
									...selectedAccess,
									mailAlert: e.target.checked === undefined ? false : e.target.checked
								})}/>

							<div className="flex flex-row gap-2">
								<div className="flex-1">
									<Button label="Cancel" severity="secondary" onClick={() => setVisible(false)}/>
								</div>
								<div className="flex-1">
									<div className="flex justify-content-end align-self-center gap-2">
										<Button label="Submit" icon="pi pi-save" onClick={saveAccess}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}
